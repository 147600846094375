import { cva } from "@/lib/style/cva.config";

export const alertVariants = cva({
	base: "relative w-full rounded-lg border border-gray-200 border-solid p-4",
	variants: {
		variant: {
			default: "bg-white text-gray-900 dark:bg-gray-900 dark:text-gray-50",
			destructive:
				"border-red-500 border-opacity-50 text-red-500 dark:border-red-500 dark:text-red-900",
		},
	},
	defaultVariants: {
		variant: "default",
	},
});
