







import { type PropType, computed, defineComponent } from "@vue/composition-api";
import { formatErrorMessage } from "@/lib/formatErrorMessage";
import Alert from "@/lib/pfg/vue2/alert/Alert.vue";
import AlertDescription from "@/lib/pfg/vue2/alert/AlertDescription.vue";
import AlertTitle from "@/lib/pfg/vue2/alert/AlertTitle.vue";

export default defineComponent({
	components: { Alert, AlertTitle, AlertDescription },
	props: {
		title: {
			type: String,
			default: "Er ging iets mis",
		},
		error: {
			type: Object as PropType<unknown>,
			default: undefined,
		},
	},
	setup(props) {
		const description = computed(() =>
			formatErrorMessage(
				props.error,
				() => "Het lijkt erop dat er iets mis ging. Probeer het later opnieuw.",
			),
		);

		return { props, description };
	},
});
