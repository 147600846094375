











/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from "@vue/composition-api";

export default defineComponent<any, any, any, any, any, any, any, any, any>({});
