// eslint-disable-next-line no-restricted-imports
import { defineConfig } from "cva";
import { twMerge } from "./twmerge.config";

export const { cva, cx, compose } = defineConfig({
	hooks: {
		onComplete: (className) => twMerge(className),
	},
});

// eslint-disable-next-line no-restricted-imports
export { type VariantProps } from "cva";
