import type { UseQueryOptions } from "@tanstack/vue-query";
import type { MaybeRef } from "@tanstack/vue-query/build/lib/types";
import { coreApi } from "@/lib/backend";

const apiCall = coreApi.members.lessonWaitingListControllerGetByLesson;
type ApiCall = typeof apiCall;
type Data = Awaited<ReturnType<ApiCall>>["data"];

export function queryOptions_lessonWaitingList(
	lessonId: MaybeRef<Parameters<ApiCall>[0]>,
): UseQueryOptions<Data, unknown, Data, readonly ["lesson", typeof lessonId, "waiting-list"]> {
	return {
		queryKey: ["lesson", lessonId, "waiting-list"] as const,
		queryFn: async (context) =>
			await apiCall(context.queryKey[1], { signal: context.signal }).then(
				(response) => response.data,
			),
		placeholderData: { data: [] } as Data,
	};
}
