

















































































































































































































































































































import { type PropType, defineComponent } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import { DateTime } from "klokwerk";
import { mapActions as mapPiniaActions, mapState, mapStores } from "pinia";
import { mapActions } from "vuex";
import ErrorAlert from "@/components/ErrorAlert.vue";
import { coreApi } from "@/lib/backend";
import type { LesUitgebreid } from "@/lib/backend/coach.api";
import { formatErrorMessage } from "@/lib/formatErrorMessage";
import { queryOptions_lessonWaitingList } from "@/lib/query/functions/lesson/waiting-list";
import { toast } from "@/lib/vue2-sonner";
import { logger } from "@/logger";
import { useAppStore } from "@/pinia/app";
import { useLocationStore } from "@/pinia/location";
import { usePopupStore } from "@/pinia/popup";
import type { RouteProps } from "@/router/types";
import { getDateFromDatumAndTijd } from "@/utils/date";

type Data = {
	loading: boolean;
	aanwezigheidslijst: Array<{
		lidNaam: string;
		lidId: number;
		deelnames: unknown;
		status: string;
	}>;
	ingediend: boolean;
	les?: LesUitgebreid & { item_type: string };
	currentTime: DateTime;
	interval?: ReturnType<typeof setInterval>;
	allowSubmissionAfterMinutes: number;
	showCancellations: boolean;
};

type Props = RouteProps<"grouplesson">;

export default defineComponent({
	components: { ErrorAlert },
	props: {
		grouplessonId: {
			type: Number as PropType<Props["grouplessonId"]>,
			required: true,
		},
	},
	setup(props) {
		const {
			status: status_waitingList,
			data: data_waitingList,
			error: error_waitingList,
		} = useQuery(queryOptions_lessonWaitingList(props.grouplessonId));

		return { props, status_waitingList, data_waitingList, error_waitingList };
	},
	data(): Data {
		return {
			loading: false,
			les: undefined,
			aanwezigheidslijst: [],
			currentTime: new DateTime(),
			interval: undefined,
			ingediend: false,
			allowSubmissionAfterMinutes: 15,
			showCancellations: false,
		};
	},
	computed: {
		...mapStores(usePopupStore),
		...mapState(useLocationStore, {
			location: "location",
			locations: "locationsSortedByName",
		}),
		...mapState(useAppStore, ["occupancyFormat"]),
		cancelable(): boolean {
			if (!this.les) return false;

			return (
				this.currentTime <
				new DateTime(getDateFromDatumAndTijd(this.les.datum, this.les.tijd)).setHours(
					(current) => current.hours - 1,
				)
			);
		},
		started(): boolean {
			if (!this.les) return false;

			return (
				this.currentTime >
				new DateTime(getDateFromDatumAndTijd(this.les.datum, this.les.tijd)).setMinutes(
					(current) => current.minutes + this.allowSubmissionAfterMinutes,
				)
			);
		},
		sortedAanwezigheidslijst() {
			const aanwezigheidslijst = this.aanwezigheidslijst as Data["aanwezigheidslijst"];

			return aanwezigheidslijst
				.filter(({ status }) => status !== "afgemeld")
				.sort((a, b) => {
					if (a.lidNaam < b.lidNaam) return -1;
					if (a.lidNaam > b.lidNaam) return 1;
					return 0;
				})
				.sort((a, b) => {
					if (a.status === "afgemeld") return 1;
					if (b.status === "afgemeld") return -1;
					if (a.deelnames === 0) return -1;
					if (b.deelnames === 0) return 1;
					return 0;
				});
		},
		cancellations() {
			const aanwezigheidslijst = this.aanwezigheidslijst as Data["aanwezigheidslijst"];

			return aanwezigheidslijst
				.filter(({ status }) => status === "afgemeld")
				.sort((a, b) => {
					if (a.lidNaam < b.lidNaam) return -1;
					else if (a.lidNaam > b.lidNaam) return 1;
					else return 0;
				})
				.sort((a, b) => {
					if (a.deelnames === 0) return -1;
					else if (b.deelnames === 0) return 1;
					else return 0;
				});
		},
	},
	watch: {
		$route: "check",
	},
	async created() {
		await this.initLocation();

		await this.check();
	},
	async destroyed() {
		if (this.interval) clearInterval(this.interval);
	},
	methods: {
		...mapPiniaActions(useLocationStore, {
			initLocation: "init",
		}),
		...mapActions("modal", ["openModal"]),
		...mapActions("groepsles", ["getAanwezigheidsLijst", "postAanwezigheidsLijst", "getLes"]),
		async check() {
			this.loading = true;

			this.ingediend = false;
			this.aanwezigheidslijst = [];
			this.interval = setInterval(() => {
				this.currentTime = new DateTime();
			}, 5000);

			await this.getRequiredInfo();

			this.loading = false;
		},
		async getRequiredInfo() {
			await this.populateAanwezigheidslijst();
			await this.populateLes();
		},
		async populateLes() {
			this.les = await this.getLes(this.props.grouplessonId);
		},
		async populateAanwezigheidslijst() {
			const data = await this.getAanwezigheidsLijst(this.props.grouplessonId);

			this.ingediend = data.ingediend;
			this.aanwezigheidslijst = data.lijst;
		},
		lijstIndienenPopup() {
			this.popupStore.open({
				title: "Let op!",
				body: `<p>Het beste tijdstip voor het indienen is <b>NA</b> de les. Er kunnen bijvoorbeeld mensen te laat komen.
                Wijzigen na indienen is niet meer mogelijk.
                </p>
                <p>
                Leden die niet aanwezig zijn:
                </p>
                <ul>
                    <li> krijgen een notificatie in de app.</li>
                    <li> krijgen straf-tijd (niet meer kunnen reserveren de komende 12 uur).</li>
                    <li> worden automatisch uitgeschreven als ze een herhalende les hebben.</li>
                </ul>
                `,
				buttons: {
					cancel: "Annuleren",
					confirm: "Indienen",
				},
				callback: () => this.lijstIndienen(),
			});
		},
		lijstIndienen() {
			const data = {
				lesId: this.props.grouplessonId,
				// clean up lijst for post
				lijst: this.aanwezigheidslijst.map((lid) => {
					if (lid.status === "aangemeld") lid.status = "geweest";
					delete lid.deelnames;
					return lid;
				}),
			};

			this.postAanwezigheidsLijst(data)
				.then((res) => {
					if (res.error) {
						logger.error("error post aanwezigheidslijst", res.error);
					} else {
						this.lijstDefinitiefIndienen();
					}
				})
				.catch((error) => {
					const message = error;
					this.popupStore.showError(
						`Er ging iets mis bij het indienen van de lijst: ${message}.<br/>Probeer het later nog eens.`,
					);
				});
		},
		async lijstDefinitiefIndienen() {
			return await toast
				.promise(coreApi.lessons.lessonAttendanceControllerMakeFinal(this.props.grouplessonId), {
					loading: "Deelnemerslijst wordt ingediend...",
					success: () => {
						this.toggleIngediend(true);
						this.populateAanwezigheidslijst();
						return "Deelnemerslijst is ingediend";
					},
					error: formatErrorMessage,
				})
				?.unwrap();
		},
		cancelLessonPopup() {
			this.openModal({
				name: "les-annuleren",
				data: { lesId: this.props.grouplessonId, les: this.les },
				callback: () => {
					this.populateAanwezigheidslijst();
					this.populateLes();
				},
			});
		},
		openReserveerModal() {
			this.openModal({
				name: "groepsles-aanmelden",
				data: { les: this.les, search: true },
				callback: () => {
					this.populateAanwezigheidslijst();
					this.populateLes();
				},
			});
		},
		openUndoLessonCancellationModal() {
			this.openModal({
				name: "undo-lesson-cancellation",
				data: {
					lesson: {
						id: this.les?.les_id,
					},
				},
				callback: async () => {
					this.les = await this.getLes(this.props.grouplessonId);
				},
			});
		},
		openAfmeldenModal(lid: unknown) {
			this.openModal({
				name: "groepsles-afmelden",
				data: { les: this.les, lid },
				callback: () => {
					this.populateAanwezigheidslijst();
				},
			});
		},
		async aanwezigChange(bool: boolean, lid: { lidId: number; status: string }) {
			lid.status = bool ? "geweest" : "niet_geweest";

			await this.postAanwezigheidsLijst({
				lesId: this.les?.les_id,
				lijst: [
					{
						lidId: lid.lidId,
						status: bool ? "aangemeld" : "niet_geweest",
					},
				],
				ingediend: false,
			});

			this.populateLes();
		},
		onOpenSendMessageModal() {
			this.openModal({
				name: "send-message",
				data: {
					les: this.les,
				},
			});
		},
		toggleIngediend(value?: boolean) {
			this.ingediend = value ? value : !this.ingediend;
		},
	},
});
